import React from 'react'
import Content from '../Content'
import PropTypes from 'prop-types'

const AboutPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <div>
      <section className='hero is-warning is-bold is-medium'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='section'>
                  <h1 className='title'>
                    {title}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section section--gradient'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='section'>
                <PageContent className='content' content={content} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="about_footer">
        <h2>Alamat: <a href="https://goo.gl/maps/1VTnazKoZNuBcTJJA">Jl KH Samanhudi 133 Harco Pasar Baru Lt Dasar/107
Jakarta, Indonesia 10710</a></h2>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1403.337524657121!2d106.83195439622838!3d-6.161516313134845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xeabb8dee2d242621!2sOptik%20Bintang%201!5e0!3m2!1sid!2ssg!4v1589559273695!5m2!1sid!2ssg" class="map" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      </footer>
    </div>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default AboutPageTemplate
